/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Custom Player Additions
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

.fade {
    opacity: 1;

    &:not(.show) {
        transition: opacity 0.15s linear;
        opacity: 0;
    }
}

.slow-fade {
    opacity: 1;

    &:not(.show) {
        transition: opacity 0.2s linear;
        opacity: 0;
    }
}

/* Icon Buttons */
.vds-button {
    border: var(--media-button-border);
    /* Custom additions - moved this from the selector above */
    padding: var(--media-button-padding, 0px);
    border-radius: var(--media-button-border-radius, 8px);
    background-color: var(--media-button-bg, rgba(0 0 0 / 0.6));
    color: var(--media-button-color, var(--media-controls-color, #f5f5f5));
    width: var(--media-button-size, 40px);
    height: var(--media-button-size, 40px);
    margin-left: 1px;
    margin-right: 1px;
    opacity: 0.8;
  }

/* Big Play Icon */
.big-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    transition: transform 0.15s linear;
    z-index: 10;
    background-color: var(--big-play-button-bg);
    opacity: 0.8;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);

    &:hover {
        background-color: var(--big-play-button-bg-hover);
        transform: scale(1.1) translate(-46%, -46%);
        transition: transform 0.2s linear, opacity 0.2s linear;
        opacity: 1;
        cursor: default;

        &:not(.fade, .slow-fade) {
            /*opacity: 0.9;*/
            cursor: pointer;
        }
    }
}

.big-play-icon-circle {
    border-radius: 100px;
}

.big-play-icon-rounded {
    border-radius:  20px;
}

.big-play-icon-square {
    border-radius: 4px;
}


.big-play-icon-small {
    width: 50px;
    height: 50px;
}

.big-play-icon-medium {
    width: 80px;
    height: 80px;
}

.big-play-icon-large {
    width: 100px;
    height: 100px;
}


.player-radius {
    border-radius: var(--video-border-radius, 16px);
}

.player-radius-small {
    border-radius: var(--video-border-radius-small, 12px);
}

.player-radius-xs {
    border-radius: var(--video-border-radius-xs, 8px);
}

/* Youtube Hack to deal with black bars on the top/bottom */
.youtube-zoom {
    margin-top: -2px;
    margin-left: -2px;
    margin-bottom: -2px;
    margin-right: -2px;
    height: 100.75%;
    width: 100.75%;
}

.vds-time-slider {
    --thumb-size: 12px;
    height: 24px;
}

.vds-video-layout .vds-controls-time-group {
    margin-bottom: -12px;
    padding-left: 4px;
    padding-right: 4px;
    /*border: 2px solid orange;*/
}

.vds-video-layout .vds-controls-time-group-small {
    margin-bottom: -8px;
    /*border: 2px solid orange;*/
}

.vds-controls-main-group-small {
    margin-bottom: 0;
    /*border: 2px solid orange;*/
}

.vds-video-layout .vds-controls-top-group {
    background: rgb(0 0 0 / 0.4);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    height: auto;
    padding: 12px;
    /*border: 2px solid orange;*/
    transform: translateY(0);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.vds-video-layout .vds-controls-top-group-small {
    background: rgb(0 0 0 / 0.4);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    height: 50px;
    /*border: 2px solid orange;*/
    transform: translateY(0);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.vds-video-layout .vds-controls-bottom-group {
    background: rgb(0 0 0 / 0.4);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    margin: 0 8px 12px 8px;
    border-radius: 8px;
    transform: translateY(0);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

    /*New bottom control bar for small layout stuff*/
.vds-video-layout .vds-controls-bottom-group-small {
    background: rgb(0 0 0 / 0.4);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 8px;
    transform: translateY(0);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.logo-small {
    margin: 0 4px 0 4px;
    height: 20px;
    /*border: 2px solid orange;*/
}

.logo-medium {
    margin: 0 4px 0 8px;
    height: 32px;
    /*border: 2px solid orange;*/
}

.logo-large {
    margin: 0 4px 0 8px;
    height: 40px;
}

/*Caption Language Switcher Placement*/
.language-switcher, .language-switcher-small {
    color: white;
    position: absolute;
    left: 50%;
    padding: 0;
    transform: translateX(-50%);
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
    /*border: 2px solid orange;*/
}

.language-switcher {
    margin: 0;
}

.language-switcher-small {
    margin: 0;
}

.language-text {
    font-weight: 300;
    font-size: 0.75rem;
}

.language-text-small {
    font-weight: 300;
    font-size: 0.75rem;
    margin-bottom: -6px;
}

.language-switcher-dropdown {
    height: auto;
    width: auto;
    margin-top: 12px;
    padding: 0 10px;
    border-width: 2px;
    background-color: var(--language-switcher-button-bg);
    border-radius: 5px;
}

.language-switcher-dropdown-small {
    height: auto;
    width: auto;
    margin-top: 0;
    padding: 0px 3px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Vertical divider for control bar */
.control-bar-divider {
    width: 1px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0 8px;
    align-self: center;
    opacity: 0.8;
}

/* Active flag in small dropdown */
.active-flag-small {
    font-size: 1.5rem;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

/* Flags */
.flag-container {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    height: 100%;
}

.flag-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;
}

.flag {
    height: auto !important;
    font-size: 2.3rem;
    line-height: 1;
    border-radius: 3px;
    margin: 0 4px;
    display: flex;
    align-items: center;
}

.flag-small {
    height: auto !important;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 3px;
    margin: 0 2px;
    display: flex;
    align-items: center;
}

.braiv-down-arrow-small {
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
    margin-left: 4px;
    transform: none;
    color: white;
    opacity: 0.9;
    width: 8px;
    height: 8px;
    display: flex;
    align-self: center;
}

.flag-selected {
    border: 2px solid var(--video-brand);
    opacity: 1;
}

.flag-not-selected {
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}

:where(.vds-language-menu) {
    min-width: 250px;
}

/* Add spacing between flag and language text in dropdown */
.vds-radio-label.flag {
    margin-right: 12px;
}

.vds-radio:hover {
    background-color: rgb(217, 217, 217, 0.5);
}

:where(.vds-radio[aria-checked='true']) {
    padding: 0;
}

/* Hide image if the src is empty, stop the broken image sign from showing momentarily during load */
.vds-poster:not([src]) {
  visibility: hidden;
}

/* Hide image if the src is empty */
.vds-poster[src=''] {
  visibility: hidden;
}

/* Video title container below top bar */
.vds-video-title-container {
    background: rgb(0 0 0 / 0.4);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    padding: 3px 12px;
    position: absolute;
    top: 70px;
    left: 8px;
    max-width: 50%;
    width: fit-content;
    z-index: 40;
    border-radius: 8px;
    transition: opacity 0.3s ease, transform 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    transform: translateY(0);
}

.vds-video-title-wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

/* Show title container when controls are visible with animation */
.vds-video-layout:has(.vds-controls[data-visible]) .vds-video-title-container {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}

/* Fallback for browsers that don't support :has() selector */
.vds-controls[data-visible] ~ .vds-video-title-container {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}

/* Hide title when video is playing with animation */
[data-playing]:not([data-controls]) .vds-video-title-container,
.vds-video-layout:has(.vds-controls:not([data-visible])) .vds-video-title-container {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-20px);
    transition: opacity 0.2s ease, transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}

/* Mobile adjustments */
@media (max-width: 576px) {
    .vds-video-title-container {
        max-width: 85%;
        padding: 4px 8px;
    }
}

.vds-video-title {
    font-size: 0.9rem;
    font-weight: 300;
    color: #fff;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
}

/* Override any default styles from the vidstack library */
.vds-video-title-container .vds-title {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100% !important;
    width: 100% !important;
    display: block !important;
}

/* Direct child selector to ensure specificity */
.vds-video-title-wrapper > .vds-title {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

/* Target any child elements of the title that might contain the actual text */
.vds-video-title-wrapper .vds-title * {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100% !important;
}

:where(.vds-time) {
    display: inline-block;
    contain: content;
    font-size: var(--media-time-font-size, 15px);
    font-weight: var(--media-time-font-weight, 300);
    font-family: var(--media-font-family, sans-serif);
    padding: var(--media-time-padding, 2px);
    border-radius: var(--media-time-border-radius, 2px);
    letter-spacing: var(--media-time-letter-spacing, 0.025em);
  }
  
  .vds-time {
    outline: 0;
    color: var(--media-time-color, var(--default-color));
    background-color: var(--media-time-bg);
    border: var(--media-time-border);
  }

/* Add these new animations for the controls visibility */
/* When controls are not visible, move top bar up and out of view */
[data-view-type='video'] .vds-controls:not([data-visible]) .vds-controls-top-group,
[data-view-type='video'] .vds-controls:not([data-visible]) .vds-controls-top-group-small {
    transform: translateY(-100%);
    transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.3s ease-in;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

/* When controls become visible, animate the top bar sliding down */
[data-view-type='video'] .vds-controls[data-visible] .vds-controls-top-group,
[data-view-type='video'] .vds-controls[data-visible] .vds-controls-top-group-small {
    transform: translateY(0);
    transition: transform 0.35s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.3s ease-out;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

/* When controls are not visible, animate bottom bars moving down */
[data-view-type='video'] .vds-controls:not([data-visible]) .vds-controls-bottom-group,
[data-view-type='video'] .vds-controls:not([data-visible]) .vds-controls-bottom-group-small {
    transform: translateY(100%);
    transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.3s ease-in;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

/* When controls become visible, animate bottom bars moving up */
[data-view-type='video'] .vds-controls[data-visible] .vds-controls-bottom-group,
[data-view-type='video'] .vds-controls[data-visible] .vds-controls-bottom-group-small {
    transform: translateY(0);
    transition: transform 0.35s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.3s ease-out;
    transition-delay: 0.05s; /* Slight delay for staggered effect */
    will-change: transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

/* Hide all controls except center play button in minimal view */
[data-media-player]:not([data-started]) .vds-video-layout[data-size='sm'] .vds-controls-top-group-small,
[data-media-player]:not([data-started]) .vds-video-layout[data-size='sm'] .vds-controls-bottom-group-small {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

/* Ensure center controls remain visible */
[data-media-player]:not([data-started]) .vds-video-layout[data-size='sm'] .vds-controls-center-group {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

/* When video starts, show all controls with animation */
[data-media-player][data-started] .vds-video-layout[data-size='sm'] .vds-controls-top-group-small,
[data-media-player][data-started] .vds-video-layout[data-size='sm'] .vds-controls-bottom-group-small {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: opacity 0.3s ease-out, visibility 0s linear;
}

/* Add a wrapper to prevent overflow during transitions */
.vds-video-layout {
    overflow: hidden;
    position: relative;
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
}

/* Ensure the media player container has proper containment */
[data-media-player] {
    contain: content;
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
}